import Vue from 'vue';
const Bus = {
    vm : new Vue(),
    $on(event,callback){
        this.vm.$off(event)
        this.vm.$on(event,callback)
    },
    $off(event,callback){
        this.vm.$off(event,callback)
    },
    $once(event,callback){
        this.vm.$once(event,callback)
    },
    $emit(event, ...args){
        this.vm.$emit(event, ...args)
    },
}
export default Bus;